<template lang="html">
  <div class="glavni">
    <div class="container">
      <div class="">
        <img src="@/assets/logonewest.svg" alt="" id="logo">
      </div>
      <div class="form-group">
        <label for="collegue">Zaposleni:</label>
        <select name="collegue" id="collegue" v-model="podredjeniName" @change="onChangePodredjeni($event)">
           <option  v-for="podr in podredjeni" v-bind:key="podr.name">{{podr.name}}</option>
        </select>
        </div>
    </div>

  </div>

<div class="buttonWrapper1">
  <button class="pdfButtons" type="button" name="button" @click="changeProcenaState(0)">Procena Kandidata</button>
  <button class="pdfButtons1" type="button" name="button" @click="changeProcenaState(1)">Psiholoska Procena</button>
</div>


<div class="" v-if="procenaState==0">
  <div class="glavni">
      <h2>Procena Kandidata</h2>
  </div>

  <div class="buttonWrapper1">
    <button class="pdfButtons" type="button" name="button" @click="changePdfStateProcena()" v-if="!procenaPdfState">Pripremi za pravljenje PDF-a</button>
    <button class="pdfButtons" type="button" name="button" @click="changePdfStateProcena()" v-if="procenaPdfState">Vrati na standardni prikaz</button>
    <button class="pdfButtons1" type="button" name="button" @click="makePDFProcena()" v-if="procenaPdfState">Napravi pdf procene</button>
  </div>


  <div class="" v-if="procenaKandidata!=null">
    <div id="procena" v-if="!procenaPdfState">
      <div class="wrap">

      <p>Legenda: 1 - Osnovno, 2 - Srednje, 3 - Odlično</p>
      <div class="container">
        <div class="">
          <p><b>Ime kandidata:</b> {{podredjeniCeo.name}}</p>
          <p><b>Godište:</b> {{procenaKandidata.godiste}}</p>
          <p><b>Prebivalište:</b> {{procenaKandidata.prebivaliste}}</p>
          <p><b>Pozicija za koju konkuriše:</b> {{procenaKandidata.pozicija}}</p>
        </div>
        <div class="">
          <p><b>Stepen edukacije:</b> {{procenaKandidata.stepenSS}}</p>
          <p><b>Stepen poznavanja Engleskog:</b> {{procenaKandidata.stepenEng}}</p>
          <p><b>Stepen poznavanja Nemačkog:</b> {{procenaKandidata.stepenNem}}</p>
          <p><b>Poznavanje rada na računaru:</b> {{procenaKandidata.stepenRacunar}}</p>
        </div>
      </div>
      </div>
      <div class="question1">
        <h3>Ukupna Ocena: {{ukupnaOcena}} ({{prosekOcena.toFixed(2)}})</h3>

      </div>

      <div class="question2">
        <h5>Legenda: 1 - izuzetno loše, 2 - nezadovoljavajuće, 3 - zadovoljavajuće, 4 - vrlo dobro, 5 - odlično</h5>
      </div>

      <div class="question1">
        <h3>1. Izgled i samopouzdanje kandidata.</h3>
        <p v-if="procenaKandidata.ans1==1"><b>Ocena - </b>izuzetno loše</p>
        <p v-if="procenaKandidata.ans1==2"><b>Ocena - </b>nezadovoljavajuće</p>
        <p v-if="procenaKandidata.ans1==3"><b>Ocena - </b>zadovoljavajuće</p>
        <p v-if="procenaKandidata.ans1==4"><b>Ocena - </b>vrlo dobro</p>
        <p v-if="procenaKandidata.ans1==5"><b>Ocena - </b>odlično</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{procenaKandidata.ans1Desc}}
          </span>
        </p>
      </div>
      <div class="question2">
        <h3>2. Komunikativnost i izražavanje.</h3>
        <p v-if="procenaKandidata.ans2==1"><b>Ocena - </b>izuzetno loše</p>
        <p v-if="procenaKandidata.ans2==2"><b>Ocena - </b>nezadovoljavajuće</p>
        <p v-if="procenaKandidata.ans2==3"><b>Ocena - </b>zadovoljavajuće</p>
        <p v-if="procenaKandidata.ans2==4"><b>Ocena - </b>vrlo dobro</p>
        <p v-if="procenaKandidata.ans2==5"><b>Ocena - </b>odlično</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{procenaKandidata.ans2Desc}}
          </span>
        </p>
      </div>

      <div class="question1">
        <h3>3. Energičnost i ambicioznost.</h3>
        <p v-if="procenaKandidata.ans3==1"><b>Ocena - </b>izuzetno loše</p>
        <p v-if="procenaKandidata.ans3==2"><b>Ocena - </b>nezadovoljavajuće</p>
        <p v-if="procenaKandidata.ans3==3"><b>Ocena - </b>zadovoljavajuće</p>
        <p v-if="procenaKandidata.ans3==4"><b>Ocena - </b>vrlo dobro</p>
        <p v-if="procenaKandidata.ans3==5"><b>Ocena - </b>odlično</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{procenaKandidata.ans3Desc}}
          </span>
        </p>
      </div>
      <div class="question2">
        <h3>4. Sposobnost organizovanja vremena.</h3>
        <p v-if="procenaKandidata.ans4==1"><b>Ocena - </b>izuzetno loše</p>
        <p v-if="procenaKandidata.ans4==2"><b>Ocena - </b>nezadovoljavajuće</p>
        <p v-if="procenaKandidata.ans4==3"><b>Ocena - </b>zadovoljavajuće</p>
        <p v-if="procenaKandidata.ans4==4"><b>Ocena - </b>vrlo dobro</p>
        <p v-if="procenaKandidata.ans4==5"><b>Ocena - </b>odlično</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{procenaKandidata.ans4Desc}}
          </span>
        </p>
      </div>

      <div class="question1">
        <h3>5. Opredeljenost ka klijentu.</h3>
        <p v-if="procenaKandidata.ans5==1"><b>Ocena - </b>izuzetno loše</p>
        <p v-if="procenaKandidata.ans5==2"><b>Ocena - </b>nezadovoljavajuće</p>
        <p v-if="procenaKandidata.ans5==3"><b>Ocena - </b>zadovoljavajuće</p>
        <p v-if="procenaKandidata.ans5==4"><b>Ocena - </b>vrlo dobro</p>
        <p v-if="procenaKandidata.ans5==5"><b>Ocena - </b>odlično</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{procenaKandidata.ans5Desc}}
          </span>
        </p>
      </div>
      <div class="question2">
        <h3>6. Prilagodljivost i timski rad.</h3>
        <p v-if="procenaKandidata.ans6==1"><b>Ocena - </b>izuzetno loše</p>
        <p v-if="procenaKandidata.ans6==2"><b>Ocena - </b>nezadovoljavajuće</p>
        <p v-if="procenaKandidata.ans6==3"><b>Ocena - </b>zadovoljavajuće</p>
        <p v-if="procenaKandidata.ans6==4"><b>Ocena - </b>vrlo dobro</p>
        <p v-if="procenaKandidata.ans6==5"><b>Ocena - </b>odlično</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{procenaKandidata.ans6Desc}}
          </span>
        </p>
      </div>

      <div class="question1">
        <h3>7. Sklonost ka odlučivanju i prepoznavanje prioriteta.</h3>
        <p v-if="procenaKandidata.ans7==1"><b>Ocena - </b>izuzetno loše</p>
        <p v-if="procenaKandidata.ans7==2"><b>Ocena - </b>nezadovoljavajuće</p>
        <p v-if="procenaKandidata.ans7==3"><b>Ocena - </b>zadovoljavajuće</p>
        <p v-if="procenaKandidata.ans7==4"><b>Ocena - </b>vrlo dobro</p>
        <p v-if="procenaKandidata.ans7==5"><b>Ocena - </b>odlično</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{procenaKandidata.ans7Desc}}
          </span>
        </p>
      </div>
      <div class="question2">
        <h3>8. Radno iskustvo.</h3>
        <p v-if="procenaKandidata.ans8==1"><b>Ocena - </b>izuzetno loše</p>
        <p v-if="procenaKandidata.ans8==2"><b>Ocena - </b>nezadovoljavajuće</p>
        <p v-if="procenaKandidata.ans8==3"><b>Ocena - </b>zadovoljavajuće</p>
        <p v-if="procenaKandidata.ans8==4"><b>Ocena - </b>vrlo dobro</p>
        <p v-if="procenaKandidata.ans8==5"><b>Ocena - </b>odlično</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{procenaKandidata.ans8Desc}}
          </span>
        </p>
      </div>

      <div class="question1">
        <h3>9. Razlozi napuštanja prethodnih radnih mesta.</h3>
        <p v-if="procenaKandidata.ans9==1"><b>Ocena - </b>izuzetno loše</p>
        <p v-if="procenaKandidata.ans9==2"><b>Ocena - </b>nezadovoljavajuće</p>
        <p v-if="procenaKandidata.ans9==3"><b>Ocena - </b>zadovoljavajuće</p>
        <p v-if="procenaKandidata.ans9==4"><b>Ocena - </b>vrlo dobro</p>
        <p v-if="procenaKandidata.ans9==5"><b>Ocena - </b>odlično</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{procenaKandidata.ans9Desc}}
          </span>
        </p>
      </div>
      <div class="question2">
        <h3>10. Najveći rezultat u karijeri.</h3>
        <p v-if="procenaKandidata.ans10==1"><b>Ocena - </b>izuzetno loše</p>
        <p v-if="procenaKandidata.ans10==2"><b>Ocena - </b>nezadovoljavajuće</p>
        <p v-if="procenaKandidata.ans10==3"><b>Ocena - </b>zadovoljavajuće</p>
        <p v-if="procenaKandidata.ans10==4"><b>Ocena - </b>vrlo dobro</p>
        <p v-if="procenaKandidata.ans10==5"><b>Ocena - </b>odlično</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{procenaKandidata.ans10Desc}}
          </span>
        </p>
      </div>

      <div class="question1">
        <h3>11. Motivacija za ovaj posao.</h3>
        <p v-if="procenaKandidata.ans11==1"><b>Ocena - </b>izuzetno loše</p>
        <p v-if="procenaKandidata.ans11==2"><b>Ocena - </b>nezadovoljavajuće</p>
        <p v-if="procenaKandidata.ans11==3"><b>Ocena - </b>zadovoljavajuće</p>
        <p v-if="procenaKandidata.ans11==4"><b>Ocena - </b>vrlo dobro</p>
        <p v-if="procenaKandidata.ans11==5"><b>Ocena - </b>odlično</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{procenaKandidata.ans11Desc}}
          </span>
        </p>
      </div>
      <div class="question2">
        <h3>12. Posedovanje vozačke dozvole.</h3>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{procenaKandidata.ans12Desc}}
          </span>
        </p>
      </div>

      <div class="question1">
        <h3>13. Otkazni rok.</h3>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{procenaKandidata.ans13Desc}}
          </span>
        </p>
      </div>
      <div class="question2">
        <h3>14. Finansijska očekivanja.</h3>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{procenaKandidata.ans14Desc}}
          </span>
        </p>
      </div>

    </div>






    <!-- +++++++++++++++++++++++++++++++ -->
    <div id="procena" v-if="procenaPdfState">

        <div id="feedBackHeader">
        <div class="centered">
        <h3 ><b>Procena Kandidata</b></h3>
        </div>

        <div class="wrap">
        <p>Legenda: 1 - Osnovno, 2 - Srednje, 3 - Odlicno</p>
        <div class="container">
          <div class="">
            <p><b>Ime kandidata:</b> {{podredjeniCeo.name}}</p>
            <p><b>Godiste:</b> {{procenaKandidata.godiste}}</p>
            <p><b>Prebivaliste:</b> {{procenaKandidata.prebivaliste}}</p>
            <p><b>Pozicija za koju konkuriše:</b> {{procenaKandidata.pozicija}}</p>
          </div>
          <div class="">
            <p><b>Stepen edukacije:</b> {{procenaKandidata.stepenSS}}</p>
            <p><b>Stepen poznavanja Engleskog:</b> {{procenaKandidata.stepenEng}}</p>
            <p><b>Stepen poznavanja Nemackog:</b> {{procenaKandidata.stepenNem}}</p>
            <p><b>Poznavanje rada na racunaru:</b> {{procenaKandidata.stepenRacunar}}</p>
          </div>
        </div>
        </div>

        <div class="questionAlt">
          <h3>Ukupna Ocena: {{ukupnaOcena}} ({{prosekOcena.toFixed(2)}})</h3>
        </div>

        </div>

        <div id="svaPitanja1">
          <div class="question">
            <h3>1. Izgled i samopouzdanje kandidata.</h3>
            <p><b>Ocena - </b>{{procenaKandidata.ans1}}</p>
            <p  style="text-align: left;">
              <span style="white-space: pre-line">
              <b>Komentar: </b>{{procenaKandidata.ans1Desc}}
              </span>
            </p>
          </div>
          <div class="questionAlt">
            <h3>2. Komunikativnost i izražavanje.</h3>
            <p><b>Ocena - </b>{{procenaKandidata.ans2}}</p>
            <p  style="text-align: left;">
              <span style="white-space: pre-line">
              <b>Komentar: </b>{{procenaKandidata.ans2Desc}}
              </span>
            </p>
          </div>

        </div>

        <div id="svaPitanja2">
          <div class="question">
            <h3>3. Energicnost i ambicioznost.</h3>
            <p><b>Ocena - </b>{{procenaKandidata.ans3}}</p>
            <p  style="text-align: left;">
              <span style="white-space: pre-line">
              <b>Komentar: </b>{{procenaKandidata.ans3Desc}}
              </span>
            </p>
          </div>

          <div class="questionAlt">
            <h3>4. Sposobnost organizovanja vremena.</h3>
            <p><b>Ocena - </b>{{procenaKandidata.ans4}}</p>
            <p  style="text-align: left;">
              <span style="white-space: pre-line">
              <b>Komentar: </b>{{procenaKandidata.ans4Desc}}
              </span>
            </p>
          </div>
        </div>

        <div id="svaPitanja3">
          <div class="question">
            <h3>5. Opredeljenost ka klijentu.</h3>
            <p><b>Ocena - </b>{{procenaKandidata.ans5}}</p>
            <p  style="text-align: left;">
              <span style="white-space: pre-line">
              <b>Komentar: </b>{{procenaKandidata.ans5Desc}}
              </span>
            </p>
          </div>
          <div class="questionAlt">
            <h3>6. Prilagodljivost i timski rad.</h3>
            <p><b>Ocena - </b>{{procenaKandidata.ans6}}</p>
            <p  style="text-align: left;">
              <span style="white-space: pre-line">
              <b>Komentar: </b>{{procenaKandidata.ans6Desc}}
              </span>
            </p>
          </div>
        </div>

        <div id="svaPitanja4">
          <div class="question">
            <h3>7. Sklonost ka odlucivanju i prepoznavanje prioriteta.</h3>
            <p><b>Ocena - </b>{{procenaKandidata.ans7}}</p>
            <p  style="text-align: left;">
              <span style="white-space: pre-line">
              <b>Komentar: </b>{{procenaKandidata.ans7Desc}}
              </span>
            </p>
          </div>
          <div class="questionAlt">
            <h3>8. Radno iskustvo.</h3>
            <p><b>Ocena - </b>{{procenaKandidata.ans8}}</p>
            <p  style="text-align: left;">
              <span style="white-space: pre-line">
              <b>Komentar: </b>{{procenaKandidata.ans8Desc}}
              </span>
            </p>
          </div>
        </div>

        <div id="svaPitanja5">
          <div class="question">
            <h3>9. Razlozi napustanja prethodnih radnih mesta.</h3>
            <p><b>Ocena - </b>{{procenaKandidata.ans9}}</p>
            <p  style="text-align: left;">
              <span style="white-space: pre-line">
              <b>Komentar: </b>{{procenaKandidata.ans9Desc}}
              </span>
            </p>
          </div>
          <div class="questionAlt">
            <h3>10. Najveci rezultat u karijeri.</h3>
            <p><b>Ocena - </b>{{procenaKandidata.ans10}}</p>
            <p  style="text-align: left;">
              <span style="white-space: pre-line">
              <b>Komentar: </b>{{procenaKandidata.ans10Desc}}
              </span>
            </p>
          </div>
        </div>

        <div id="svaPitanja6">
          <div class="question">
            <h3>11. Motivacija za ovaj posao.</h3>
            <p><b>Ocena - </b>{{procenaKandidata.ans11}}</p>
            <p  style="text-align: left;">
              <span style="white-space: pre-line">
              <b>Komentar: </b>{{procenaKandidata.ans11Desc}}
              </span>
            </p>
          </div>
          <div class="questionAlt">
            <h3>12. Posedovanje vozacke dozvole.</h3>
            <p  style="text-align: left;">
              <span style="white-space: pre-line">
              <b>Komentar: </b>{{procenaKandidata.ans12Desc}}
              </span>
            </p>
          </div>
        </div>

        <div id="svaPitanja7">
          <div class="question">
            <h3>13. Otkazni rok.</h3>
            <p  style="text-align: left;">
              <span style="white-space: pre-line">
              <b>Komentar: </b>{{procenaKandidata.ans13Desc}}
              </span>
            </p>
          </div>
          <div class="questionAlt">
            <h3>14. Finansijska  ocekivanja.</h3>
            <p  style="text-align: left;">
              <span style="white-space: pre-line">
              <b>Komentar: </b>{{procenaKandidata.ans14Desc}}
              </span>
            </p>
          </div>
        </div>


    </div>






    <!-- ++++++++++++++++++++++++++++++++++++ -->

  </div>

  <div class="" v-if="procenaKandidata==null">
    <h3>Procena kandidata nije urađena</h3>
  </div>
</div>

<div class="" v-if="procenaState==1">

  <div class="glavni">
      <h2>Psihološka Procena</h2>
  </div>

  <div class="" v-if="pdfState==0 && procenaCela!=null">

  <div class="buttonWrapper">
  <button class="pdfButtons" type="button" name="button" @click="changePdfState">Pripremi za pravljenje PDF-a</button>
  </div>
  <div id="pdf" >
    <div class="wrapper">
    <div class="page" id="page1">
      <div class="headerProcena">
        <h4>REZULTATI TESTIRANJA OSOBINA LIČNOSTI </h4>
        <h4>(NEO PI-R test ličnosti)</h4>
      </div>
      <div class="bodyProcena">

        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          {{procenaCela.opstaOcena}}
          </span>
        </p>
      </div>
    </div>

    <div class="page" id="page2">
      <div class="headerProcena">
        <h4>OSOBINE LIČNOSTI (POZITIVNE)</h4>
      </div>
      <div class="bodyProcena">
        <ul class="" v-for="osobina in procenaCela.pozitivno" v-bind:key="osobina">
          <li>{{osobina}}</li>
        </ul>
      </div>
    </div>

    <div class="page" id="page3">
      <div class="headerProcena">
        <h4>OSOBINE LIČNOSTI (NEGATIVNE)</h4>
      </div>
      <div class="bodyProcena">
        <ul class="" v-for="osobina in procenaCela.negativno" v-bind:key="osobina">
          <li>{{osobina}}</li>
        </ul>
      </div>
    </div>

    <div class="page" id="page4">
      <div class="headerProcena">
        <h4>INTEGRISANO MIŠLJENJE PSIHOLOGA </h4>
      </div>
      <div class="bodyProcena">
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          {{procenaCela.misljenjePsihologa}}
          </span>
        </p>
      </div>
    </div>

    <div class="page" id="page5">
      <div class="headerProcena">
        <h4>REZULTATI NA OSNOVU VQ TESTA </h4>
      </div>
      <div class="" v-if="!procenaCela.savedVq">
      <div class="bodyProcena">
        <div id="aa">

          <div class="vqPovucen" v-if="vqResult<=-15">
            <h3>"VQ" prоfil је: "Pоvučеn". ({{vqResult}})</h3>
            <p>Vаšа "vоlја zа živоtоm"  је slаbа ili pоtisnutа. Vi nistе zаdоvоlјni svеtоm ili živоtоm tаkvim kаkvi jesu, аli nistе sklоni dа ih menjate. Gеnеrаlnо sе оsеćаtе ugrоžеnim, dоgаđајimа i lјudimа. Višе vоlitе dа sе pоvučеtе nеgо dа se eksponirate. Žеlitе dа vоditе mirаn živоt, dаlеkо оd svеtskоg mеtеžа. Nеmаtе ni јаkе žеlје, ni јаkе аmbiciје. Drugi Vаs mоgu dоživеti kао pаsivnе ili stаtičnе. Bеz iniciјаtivе, nеprеduzimlјivе. Kао nеkоg kо višе vоli kоntеmplаciјu оd аkciје. Vеrоvаtnо је Vаš mоtо: "Nеmа pоtrеbе gurаti rеku, оnа svаkаkо tеčе."</p>

          </div>
          <div class="vqOprezan"  v-if="(vqResult>-15)&&(vqResult<=-1)">
            <h3>"VQ" Profil je: "Oprezan." ({{vqResult}})</h3>
            <p>Vasa "volja za zivotom" je u pripravnosti. Vi ste budni, posmatrate svet oko sebe i procenjujete potencijalni uticaj dogadjaja na Vas zivot. Spremni ste da reagujete ako je potrebno, ali niste skloni da se pokrenete i preuzmete inicijativu, ukoliko niste primorani na to. </p>
            <p>Pоkušаćete dа izbеgnе rizikе i dа sе zаštititе оd nеоčеkivаnоg. Pоuzdаni ste kао prоfеsiоnаlаc i kао priјаtеlј, svе dоk sе nе nаđеtе u kоntrоvеrznој ili rizičnој situаciјi. Prihvаtićеtе оpšti trеnd rаzmišlјаnjа zа kојi prоcеnitе dа је rаzumаn. U principu vоlitе lјudе, аli žеlitе dа prоvеritе dа li su pоuzdаni. Kаdа sе sа nеkim priјаtnо оsеćаtе, dаćеtе svе оd sеbе dа mu budеtе оd pоmоći. Drugi Vаs dоživlјаvајu prе kао nеkоg kо prihvаtа inciјаtivu, nеgо kао iniciјаtоrа. Kао nеkоg kо ćе sеdеti nа оgrаdi dоk nе vidi u kоm prаvcu vеtаr duvа. Kао stеrеоtipnоg pо nаčinu rаzmišlјаnjа i kао nе bаš stimulаtivnоg. Vаš mоtо је vеrоvаtnо: "Višе vоlim dа igrаm nа sigurnо".</p>

          </div>
          <div class="vqAngazovan" v-if="(vqResult>=1)&&(vqResult<=14)">
            <h3>"VQ" Profil je:"Angažovan". ({{vqResult}})</h3>
            <p>Vаšа "vоlја zа živоtоm" је аktivirаnа. Vi stе "аktеr". Pоkrеćе Vаs svеt. Živоt је pun mоgućnоsti kоје su i zаbаvnе i kоrisnе. Žеlitе dа idеtе dаlје: dа rаditе stvаri i dа uživаtе u živоtu. Vi ćеtе inicirаti nоvе prојеktе, kаkо u Vаšеm prоfеsiоnаlnоm, tаkо i u Vаšеm ličnоm živоtu. Privućićе Vаs lјudi sа kојimа оsеćаte dа mоžеtе dа rаditе rаznе stvаri: pаrtnеri, prе nеgо drugоvi ili srоdnе dušе. Vоlitе prеuzimаnjе оdgоvоrnоsti i nе plаšitе sе оd pојаvlјivаnjа u јаvnоsti i prihvatanja rizikа. Маštоviti stе.</p>
            <p>Drugi lјudi ćе Vаs vеrоvаtnо dоživеti kао lidеrа, kао nеkоg sа idејаmа i iniciјаtivоm, аktivnоg i pоuzdаnоg, zаnimlјivоg. Моždа ćе Vаs dоživеti i kао prеvišе zаuzеtоg, kао nеkоg kо sе nе prеdаје zаbаvi ili оpuštаnju, kао nеkоg kоgа sе nе tiču ličnа оsеćаnjа ili prоblеmi drugih lјudi, kао еgоistu.</p>
            <p>Vаš mоtо је vеrоvаtnо:"Hајdе dа tо urаdimо!"</p>
          </div>
          <div class="vqHiperaktivan"  v-if="vqResult>=15">
            <h3>"VQ" prоfil је: "Hipеr-аktivаn". ({{vqResult}})</h3>
            <p>Vаšа "vоlја zа živоtоm" је оgrоmnа. Vi stе "prеduzеtnik", "оsvајаč". U sebi kоnstаntnо osećate pritisаk. Vi sе nikаdа nе mоžеtе stvаrnо оpustiti i čаk i kаdа uživаtе u zаbаvi, uvеk i dаlје rаzmišlјаtе о nеkоm pоslu kојi trеbа dа оbаvitе. Моrаtе biti аngаžоvаni u nеkоm prојеktu i morate imati osećaj dа  stаlnо širitе svојu "mоć zа živоt". Pо Vаmа, "svеt trеbа zgrаbiti!" Vi stе nеstrplјivi, nikаd zаdоvоlјni, оsim tоkоm krаtkih trеnutаkа pоstignućа. Pоtrеbnо Vаm је dа vоditе, dа prеuzimаtе оdgоvоrnоst, јеr u suprоtnоm mislitе dа sе stvаri nеćе оdviјаti brzо ili dоvоlјnо еfikаsnо, оdnоsnо nеćе ići "nа Vаš nаčin". Drugi lјudi su u suštini izvršiоci Vаših pоduhvаtа, oni trеbа dа iznеsu Vаšе prојеktе. Nistе uоpštе zаintеrеsоvаni zа njihоv lični živоt. Vеоmа stе krеаtivni. Оd Vаs mоžе pоstаti vеliki lidеr, pоd uslоvоm dа nе prеtеruјеtе ...</p>
            <p>Drugi Vаs uglаvnоm vidе kао hipеr-аktivаnоg, vrlо mаštоvitоg, uvеk u pоkrеtu, inоvаtivnоg. Sа mаnjе pоzitivnе strаnе, mоgli bi Vаs smаtrаti zа prеvišе nаpаdnоg, аrоgаntnоg, аgrеsivnоg, nаpоrnоg, zаpоvеdnički nаstrојеnоg. Kао nikаd zаdоvоlјnоg rаdоhоličаrа, kојi nе shvаtа u čеmu su prаvе vrеdnоsti živоtа. Kао nеоsеtlјivоg prеmа drugim lјudimа i bеz ličnоg živоtа.</p>
            <p>Vаš mоtо је vеrоvаtnо: "Višе је bоlје".</p>
          </div>


        </div>
      </div>
      </div>

      <div class="" v-if="procenaCela.savedVq">
        <div class="bodyProcena">
        <h3>{{procenaCela.vqHeader}}</h3>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          {{procenaCela.vqBody}}
          </span>
        </p>
        </div>
      </div>
    </div>

  </div>
  </div>
  </div>


  <div class="" v-if="pdfState==1 && procenaCela!=null">
    <button class="pdfButtons" type="button" name="button" @click="changePdfState">Vrati na standardni prikaz</button>
    <button class="pdfButtons1" type="button" name="button" @click="makePDF">Sacuvaj PDF</button>
    <button class="pdfButtons" type="button" name="button" @click="makePDFVQ">Sacuvaj PDF VQ</button>
    <div id="pdfx" >

      <div class="pagex" id="page1">
        <div class="headerProcena">
          <h4>Rezultati testiranja osobina licnosti</h4>
          <h4>(NEO PI-R test licnosti)</h4>
        </div>
        <div class="bodyProcena">

          <p  style="text-align: left;">
            <span style="white-space: pre-line">
            {{procenaCela.opstaOcena}}
            </span>
          </p>
        </div>
      </div>

      <div class="pagex" id="page2">
        <div class="headerProcena">
          <h4>Osobine licnosti (pozitivne)</h4>
        </div>
        <div class="bodyProcena">
          <ul class="" v-for="osobina in procenaCela.pozitivno" v-bind:key="osobina">
            <li>{{osobina}}</li>
          </ul>
        </div>
      </div>

      <div class="pagex" id="page3">
        <div class="headerProcena">
          <h4>Osobine licnosti (negativne)</h4>
        </div>
        <div class="bodyProcena">
          <ul class="" v-for="osobina in procenaCela.negativno" v-bind:key="osobina">
            <li>{{osobina}}</li>
          </ul>
        </div>
      </div>

      <div class="pagex" id="page4">
        <div class="headerProcena">
          <h4>Integrisano mišljenje psihologa</h4>
        </div>
        <div class="bodyProcena">
          <p  style="text-align: left;">
            <span style="white-space: pre-line">
            {{procenaCela.misljenjePsihologa}}
            </span>
          </p>
        </div>
      </div>




    </div>

    <div class="pdfVQ" id="vqTest">
      <div class="" v-if="!procenaCela.savedVq">
      <div class="vqPovucen" v-if="vqResult<=-15">
        <h3>"VQ" prоfil је: "Pоvučеn". ({{vqResult}})</h3>
        <p>Vаšа "vоlја zа živоtоm"  је slаbа ili pоtisnutа. Vi nistе zаdоvоlјni svеtоm ili živоtоm tаkvim kаkvi jesu, аli nistе sklоni dа ih menjate. Gеnеrаlnо sе оsеćаtе ugrоžеnim, dоgаđајimа i lјudimа. Višе vоlitе dа sе pоvučеtе nеgо dа se eksponirate. Žеlitе dа vоditе mirаn živоt, dаlеkо оd svеtskоg mеtеžа. Nеmаtе ni јаkе žеlје, ni јаkе аmbiciје. Drugi Vаs mоgu dоživеti kао pаsivnе ili stаtičnе. Bеz iniciјаtivе, nеprеduzimlјivе. Kао nеkоg kо višе vоli kоntеmplаciјu оd аkciје. Vеrоvаtnо је Vаš mоtо: "Nеmа pоtrеbе gurаti rеku, оnа svаkаkо tеčе."</p>

      </div>
      <div class="vqOprezan"  v-if="(vqResult>-15)&&(vqResult<=-1)">
        <h3>"VQ" Profil je: "Oprezan." ({{vqResult}})</h3>
        <p>Vasa "volja za zivotom" je u pripravnosti. Vi ste budni, posmatrate svet oko sebe i procenjujete potencijalni uticaj dogadjaja na Vas zivot. Spremni ste da reagujete ako je potrebno, ali niste skloni da se pokrenete i preuzmete inicijativu, ukoliko niste primorani na to. </p>
        <p>Pоkušаćete dа izbеgnе rizikе i dа sе zаštititе оd nеоčеkivаnоg. Pоuzdаni ste kао prоfеsiоnаlаc i kао priјаtеlј, svе dоk sе nе nаđеtе u kоntrоvеrznој ili rizičnој situаciјi. Prihvаtićеtе оpšti trеnd rаzmišlјаnjа zа kојi prоcеnitе dа је rаzumаn. U principu vоlitе lјudе, аli žеlitе dа prоvеritе dа li su pоuzdаni. Kаdа sе sа nеkim priјаtnо оsеćаtе, dаćеtе svе оd sеbе dа mu budеtе оd pоmоći. Drugi Vаs dоživlјаvајu prе kао nеkоg kо prihvаtа inciјаtivu, nеgо kао iniciјаtоrа. Kао nеkоg kо ćе sеdеti nа оgrаdi dоk nе vidi u kоm prаvcu vеtаr duvа. Kао stеrеоtipnоg pо nаčinu rаzmišlјаnjа i kао nе bаš stimulаtivnоg. Vаš mоtо је vеrоvаtnо: "Višе vоlim dа igrаm nа sigurnо".</p>

      </div>
      <div class="vqAngazovan" v-if="(vqResult>=1)&&(vqResult<=14)">
        <h3>"VQ" Profil je:"Angažovan". ({{vqResult}})</h3>
        <p>Vаšа "vоlја zа živоtоm" је аktivirаnа. Vi stе "аktеr". Pоkrеćе Vаs svеt. Živоt је pun mоgućnоsti kоје su i zаbаvnе i kоrisnе. Žеlitе dа idеtе dаlје: dа rаditе stvаri i dа uživаtе u živоtu. Vi ćеtе inicirаti nоvе prојеktе, kаkо u Vаšеm prоfеsiоnаlnоm, tаkо i u Vаšеm ličnоm živоtu. Privućićе Vаs lјudi sа kојimа оsеćаte dа mоžеtе dа rаditе rаznе stvаri: pаrtnеri, prе nеgо drugоvi ili srоdnе dušе. Vоlitе prеuzimаnjе оdgоvоrnоsti i nе plаšitе sе оd pојаvlјivаnjа u јаvnоsti i prihvatanja rizikа. Маštоviti stе.</p>
        <p>Drugi lјudi ćе Vаs vеrоvаtnо dоživеti kао lidеrа, kао nеkоg sа idејаmа i iniciјаtivоm, аktivnоg i pоuzdаnоg, zаnimlјivоg. Моždа ćе Vаs dоživеti i kао prеvišе zаuzеtоg, kао nеkоg kо sе nе prеdаје zаbаvi ili оpuštаnju, kао nеkоg kоgа sе nе tiču ličnа оsеćаnjа ili prоblеmi drugih lјudi, kао еgоistu.</p>
        <p>Vаš mоtо је vеrоvаtnо:"Hајdе dа tо urаdimо!"</p>
      </div>
      <div class="vqHiperaktivan"  v-if="vqResult>=15">
        <h3>"VQ" prоfil је: "Hipеr-аktivаn". ({{vqResult}})</h3>
        <p>Vаšа "vоlја zа živоtоm" је оgrоmnа. Vi stе "prеduzеtnik", "оsvајаč". U sebi kоnstаntnо osećate pritisаk. Vi sе nikаdа nе mоžеtе stvаrnо оpustiti i čаk i kаdа uživаtе u zаbаvi, uvеk i dаlје rаzmišlјаtе о nеkоm pоslu kојi trеbа dа оbаvitе. Моrаtе biti аngаžоvаni u nеkоm prојеktu i morate imati osećaj dа  stаlnо širitе svојu "mоć zа živоt". Pо Vаmа, "svеt trеbа zgrаbiti!" Vi stе nеstrplјivi, nikаd zаdоvоlјni, оsim tоkоm krаtkih trеnutаkа pоstignućа. Pоtrеbnо Vаm је dа vоditе, dа prеuzimаtе оdgоvоrnоst, јеr u suprоtnоm mislitе dа sе stvаri nеćе оdviјаti brzо ili dоvоlјnо еfikаsnо, оdnоsnо nеćе ići "nа Vаš nаčin". Drugi lјudi su u suštini izvršiоci Vаših pоduhvаtа, oni trеbа dа iznеsu Vаšе prојеktе. Nistе uоpštе zаintеrеsоvаni zа njihоv lični živоt. Vеоmа stе krеаtivni. Оd Vаs mоžе pоstаti vеliki lidеr, pоd uslоvоm dа nе prеtеruјеtе ...</p>
        <p>Drugi Vаs uglаvnоm vidе kао hipеr-аktivаnоg, vrlо mаštоvitоg, uvеk u pоkrеtu, inоvаtivnоg. Sа mаnjе pоzitivnе strаnе, mоgli bi Vаs smаtrаti zа prеvišе nаpаdnоg, аrоgаntnоg, аgrеsivnоg, nаpоrnоg, zаpоvеdnički nаstrојеnоg. Kао nikаd zаdоvоlјnоg rаdоhоličаrа, kојi nе shvаtа u čеmu su prаvе vrеdnоsti živоtа. Kао nеоsеtlјivоg prеmа drugim lјudimа i bеz ličnоg živоtа.</p>
        <p>Vаš mоtо је vеrоvаtnо: "Višе је bоlје".</p>
      </div>
    </div>

    <div class="" v-if="procenaCela.savedVq">
      <div class="bodyProcena">
      <h3>{{procenaCela.vqHeader}}</h3>
      <p  style="text-align: left;">
        <span style="white-space: pre-line">
        {{procenaCela.vqBody}}
        </span>
      </p>
      </div>
    </div>
    </div>

  </div>



  <div class="" v-if="procenaCela==null">
    <h2>Nije urađena procena</h2>
  </div>
</div>

</template>

<script>
// eslint-disable-next-line no-unused-vars
import html2canvas from 'html2canvas';
// eslint-disable-next-line no-unused-vars
import jsPDF from 'jspdf';
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import {mapActions,mapGetters} from 'vuex';
export default {
  computed: mapGetters(['user','users','companys','usersSameCompany','psiholoskaProcenas']),
  data(){
    return{
      procenaPdfState:false,
      podredjeni:[],
      podredjeniCeo:{},
      podredjeniName:"",
      procenaCela:{},
      vqResult:0,
      pdfState:0,
      procenaState:0,
      procenaKandidata:null,
      ukupnaOcena:0,
      prosekOcena:0,
    }
  },
  methods:{
    ...mapActions(['getProfile','getCompanies','getCollegues','getAllPsiholoskaProcena','getAllUsers']),
    changePdfStateProcena(){
      if(this.procenaPdfState){
        this.procenaPdfState=false;
      }else{
        this.procenaPdfState=true;
      }
    },
    onChangePodredjeni(event){
      
      this.procenaCela=null;
      for (var i = 0; i < this.usersSameCompany.length; i++) {
        if(this.usersSameCompany[i].name == event.target.value){
          this.podredjeniCeo=this.usersSameCompany[i];
        }
      }
      for ( i = 0; i < this.psiholoskaProcenas.length; i++) {
        if(this.podredjeniCeo._id==this.psiholoskaProcenas[i].idUser){
          this.procenaCela=this.psiholoskaProcenas[i];
        }
      }
      this.ukupnaOcena=0;
      this.procenaKandidata=this.podredjeniCeo.procenaKandidata;



      if(this.procenaKandidata!=null){
        this.ukupnaOcena+=parseInt(this.procenaKandidata.ans1);
        this.ukupnaOcena+=parseInt(this.procenaKandidata.ans2);
        this.ukupnaOcena+=parseInt(this.procenaKandidata.ans3);
        this.ukupnaOcena+=parseInt(this.procenaKandidata.ans4);
        this.ukupnaOcena+=parseInt(this.procenaKandidata.ans5);
        this.ukupnaOcena+=parseInt(this.procenaKandidata.ans6);
        this.ukupnaOcena+=parseInt(this.procenaKandidata.ans7);
        this.ukupnaOcena+=parseInt(this.procenaKandidata.ans8);
        this.ukupnaOcena+=parseInt(this.procenaKandidata.ans9);
        this.ukupnaOcena+=parseInt(this.procenaKandidata.ans10);
        this.ukupnaOcena+=parseInt(this.procenaKandidata.ans11);

        this.prosekOcena=this.ukupnaOcena/11;
      }

      ////////////
      // if(this.procenaKandidata.ans1 == 1){
      //   this.procenaKandidata.ans1="izuzetno loše"
      // }
      // if(this.procenaKandidata.ans1 == 2){
      //   this.procenaKandidata.ans1="nezadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans1 == 3){
      //   this.procenaKandidata.ans1="zadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans1 == 4){
      //   this.procenaKandidata.ans1="vrlo dobro"
      // }
      // if(this.procenaKandidata.ans1 == 5){
      //   this.procenaKandidata.ans1="odlično"
      // }
      // ////////////
      // ////////////
      // if(this.procenaKandidata.ans2 == 1){
      //   this.procenaKandidata.ans2="izuzetno loše"
      // }
      // if(this.procenaKandidata.ans2 == 2){
      //   this.procenaKandidata.ans2="nezadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans2 == 3){
      //   this.procenaKandidata.ans2="zadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans2 == 4){
      //   this.procenaKandidata.ans2="vrlo dobro"
      // }
      // if(this.procenaKandidata.ans2 == 5){
      //   this.procenaKandidata.ans2="odlično"
      // }
      // ////////////
      // ////////////
      // if(this.procenaKandidata.ans3 == 1){
      //   this.procenaKandidata.ans3="izuzetno loše"
      // }
      // if(this.procenaKandidata.ans3 == 2){
      //   this.procenaKandidata.ans3="nezadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans3 == 3){
      //   this.procenaKandidata.ans3="zadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans3 == 4){
      //   this.procenaKandidata.ans3="vrlo dobro"
      // }
      // if(this.procenaKandidata.ans3 == 5){
      //   this.procenaKandidata.ans3="odlično"
      // }
      // ////////////
      // ////////////
      // if(this.procenaKandidata.ans4 == 1){
      //   this.procenaKandidata.ans4="izuzetno loše"
      // }
      // if(this.procenaKandidata.ans4 == 2){
      //   this.procenaKandidata.ans4="nezadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans4 == 3){
      //   this.procenaKandidata.ans4="zadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans4 == 4){
      //   this.procenaKandidata.ans4="vrlo dobro"
      // }
      // if(this.procenaKandidata.ans4 == 5){
      //   this.procenaKandidata.ans4="odlično"
      // }
      // ////////////
      // ////////////
      // if(this.procenaKandidata.ans5 == 1){
      //   this.procenaKandidata.ans5="izuzetno loše"
      // }
      // if(this.procenaKandidata.ans5 == 2){
      //   this.procenaKandidata.ans5="nezadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans5 == 3){
      //   this.procenaKandidata.ans5="zadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans5 == 4){
      //   this.procenaKandidata.ans5="vrlo dobro"
      // }
      // if(this.procenaKandidata.ans5 == 5){
      //   this.procenaKandidata.ans5="odlično"
      // }
      // ////////////
      // ////////////
      // if(this.procenaKandidata.ans6 == 1){
      //   this.procenaKandidata.ans6="izuzetno loše"
      // }
      // if(this.procenaKandidata.ans6 == 2){
      //   this.procenaKandidata.ans6="nezadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans6 == 3){
      //   this.procenaKandidata.ans6="zadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans6 == 4){
      //   this.procenaKandidata.ans6="vrlo dobro"
      // }
      // if(this.procenaKandidata.ans6 == 5){
      //   this.procenaKandidata.ans6="odlično"
      // }
      // ////////////
      // ////////////
      // if(this.procenaKandidata.ans7 == 1){
      //   this.procenaKandidata.ans7="izuzetno loše"
      // }
      // if(this.procenaKandidata.ans7 == 2){
      //   this.procenaKandidata.ans7="nezadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans7 == 3){
      //   this.procenaKandidata.ans7="zadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans7 == 4){
      //   this.procenaKandidata.ans7="vrlo dobro"
      // }
      // if(this.procenaKandidata.ans7 == 5){
      //   this.procenaKandidata.ans7="odlično"
      // }
      // ////////////
      // ////////////
      // if(this.procenaKandidata.ans8 == 1){
      //   this.procenaKandidata.ans8="izuzetno loše"
      // }
      // if(this.procenaKandidata.ans8 == 2){
      //   this.procenaKandidata.ans8="nezadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans8 == 3){
      //   this.procenaKandidata.ans8="zadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans8 == 4){
      //   this.procenaKandidata.ans8="vrlo dobro"
      // }
      // if(this.procenaKandidata.ans8 == 5){
      //   this.procenaKandidata.ans8="odlično"
      // }
      // ////////////
      // ////////////
      // if(this.procenaKandidata.ans9 == 1){
      //   this.procenaKandidata.ans9="izuzetno loše"
      // }
      // if(this.procenaKandidata.ans9 == 2){
      //   this.procenaKandidata.ans9="nezadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans9 == 3){
      //   this.procenaKandidata.ans9="zadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans9 == 4){
      //   this.procenaKandidata.ans9="vrlo dobro"
      // }
      // if(this.procenaKandidata.ans9 == 5){
      //   this.procenaKandidata.ans9="odlično"
      // }
      // ////////////
      // ////////////
      // if(this.procenaKandidata.ans10 == 1){
      //   this.procenaKandidata.ans10="izuzetno loše"
      // }
      // if(this.procenaKandidata.ans10 == 2){
      //   this.procenaKandidata.ans10="nezadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans10 == 3){
      //   this.procenaKandidata.ans10="zadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans10 == 4){
      //   this.procenaKandidata.ans10="vrlo dobro"
      // }
      // if(this.procenaKandidata.ans10 == 5){
      //   this.procenaKandidata.ans10="odlično"
      // }
      // ////////////
      // ////////////
      // if(this.procenaKandidata.ans11 == 1){
      //   this.procenaKandidata.ans11="izuzetno loše"
      // }
      // if(this.procenaKandidata.ans11 == 2){
      //   this.procenaKandidata.ans11="nezadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans11 == 3){
      //   this.procenaKandidata.ans11="zadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans11 == 4){
      //   this.procenaKandidata.ans11="vrlo dobro"
      // }
      // if(this.procenaKandidata.ans11 == 5){
      //   this.procenaKandidata.ans11="odlično"
      // }
      // ////////////
      // ////////////
      // if(this.procenaKandidata.ans12 == 1){
      //   this.procenaKandidata.ans12="izuzetno loše"
      // }
      // if(this.procenaKandidata.ans12 == 2){
      //   this.procenaKandidata.ans12="nezadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans12 == 3){
      //   this.procenaKandidata.ans12="zadovoljavajuće"
      // }
      // if(this.procenaKandidata.ans12 == 4){
      //   this.procenaKandidata.ans12="vrlo dobro"
      // }
      // if(this.procenaKandidata.ans12 == 5){
      //   this.procenaKandidata.ans12="odlično"
      // }
      // ////////////
      // if(this.procenaKandidata.stepenEng==1){
      //   this.procenaKandidata.stepenEng="Osnovno";
      // }
      // if(this.procenaKandidata.stepenEng==2){
      //   this.procenaKandidata.stepenEng="Srednje";
      // }
      // if(this.procenaKandidata.stepenEng==3){
      //   this.procenaKandidata.stepenEng="Odlično";
      // }
      // //////////
      // ////////////
      // if(this.procenaKandidata.stepenNem==1){
      //   this.procenaKandidata.stepenNem="Osnovno";
      // }
      // if(this.procenaKandidata.stepenNem==2){
      //   this.procenaKandidata.stepenNem="Srednje";
      // }
      // if(this.procenaKandidata.stepenNem==3){
      //   this.procenaKandidata.stepenNem="Odlično";
      // }
      // //////////
      // ////////////
      // if(this.procenaKandidata.stepenRacunar==1){
      //   this.procenaKandidata.stepenRacunar="Osnovno";
      // }
      // if(this.procenaKandidata.stepenRacunar==2){
      //   this.procenaKandidata.stepenRacunar="Srednje";
      // }
      // if(this.procenaKandidata.stepenRacunar==3){
      //   this.procenaKandidata.stepenRacunar="Odlično";
      // }
      // //////////



      if(this.podredjeniCeo.vqTest.finished){
        this.vqResult=this.podredjeniCeo.vqTest.result;
      }
    },
    changeProcenaState(number){
      this.procenaState=number;
    },
    changePdfState(){
      if(this.pdfState==0){
        this.pdfState=1;
      }else{
        this.pdfState=0;
      }
    },
    makePDF(){
      window.html2canvas=html2canvas;
      //p = portrait (moze i l za landscape)
      //pt za measurement
      //a4 za format
      var doc = new jsPDF("p","pt","a3");
      doc.html(document.querySelector("#pdfx"),{
        callback: function(pdf){
          pdf.save("PsiholoskaProcena.pdf");
        }
      });
    },
    makePDFProcena(){


      window.html2canvas=html2canvas;
      //p = portrait (moze i l za landscape)
      //pt za measurement
      //a4 za format
      var doc = new jsPDF("l","pt","a4");
      doc.html(document.querySelector("#procena"),{
        callback: function(pdf){
          pdf.save("mypdf.pdf");
        }
      });
    },

    async makePDFVQ(){

      var downloading = document.getElementById("vqTest");
      var doc = new jsPDF("l","pt","a4");

      await html2canvas(downloading,{
        scale: 2,
        allowTaint : true,
        useCORS: true,
        width: 810,
        height: 560
      }).then((canvas)=>{
        doc.addImage(canvas.toDataURL("image/png"),'PNG',5,5,810, 560);
      });

      doc.save("VQTest.pdf");

    },
  async  madePDFPic(){
      var downloading = document.getElementById("pdf");
      var doc = new jsPDF("l","pt","a4");

      await html2canvas(downloading,{
        scale: 2,
        allowTaint : true,
        useCORS: true,
        width: 810,
        height: 560
      }).then((canvas)=>{
        doc.addImage(canvas.toDataURL("image/png"),'PNG',5,5,810, 560);
      });

      doc.save("Document.pdf");

    }

  },

  async created(){
    await this.getProfile();
    await this.getCollegues(this.user.type);
    await this.getAllPsiholoskaProcena();
     // await this.getAllUsers();
    this.procenaKandidata=null;
    this.procenaCela=null;
    for (var j = 0; j < this.usersSameCompany.length; j++) {
      if(this.user.name==this.usersSameCompany[j].nadredjeni){       
        this.podredjeni.push(this.usersSameCompany[j]);
      }
    }

    // let procenas = [];
    // for (var i = 0; i < this.psiholoskaProcenas.length; i++) {
    //   for (var j = i+1; j < this.psiholoskaProcenas.length; j++) {
    //     if(this.psiholoskaProcenas[i].idUser==this.psiholoskaProcenas[j].idUser){
    //       procenas.push(this.psiholoskaProcenas[i]);
    //     }
    //   }
    // }



    // ______________________________________________________________________________________________
    //ISPOD JE SKRIPTA ZA TRAZENJE GRESAKA Č,Ć,Đ




//     let counterHit=0;
//     let entireText="";
//
// // let ignored=["622a15cfc077400016fd9278","622a1627c077400016fd9280","622a16c3c077400016fd928c","622a251ec077400016fd9343","622a260ec077400016fd9353","622a2592c077400016fd934b",
// // "622a2467c077400016fd933b","622a2429c077400016fd9337","622a2390c077400016fd932f","622a2358c077400016fd932b","622a2284c077400016fd931f","622a2250c077400016fd931b","622a21f2c077400016fd9313"];
//     for (var i = 1; i < this.psiholoskaProcenas.length; i++) {
//       // let flag=true;
//       // for (var x = 0; x < ignored.length; x++) {
//       //   if(ignored[x]==this.psiholoskaProcenas[i].idUser){
//       //     flag=false;
//       //   }
//       // }
//
//
//       let stringTemp="";
//       // console.log(procenas[i].opstaOcena);
//       stringTemp+=this.psiholoskaProcenas[i].opstaOcena;
//
//       for (var j = 0; j < stringTemp.length; j++) {
//         if(stringTemp[j]=='č' ||stringTemp[j]=='ć'  ||stringTemp[j]=='đ' ){
//           entireText+="OPSTA OCENA"+"\n";
//           counterHit++;
//           entireText+="Nadjen nepozeljan karakter za:"+this.psiholoskaProcenas[i].idUser +", "+j+". karakter, :"+stringTemp[j]+"\n";
//           let subStr=stringTemp.substring(j-50,j+50);
//           entireText+="tekst: "+subStr+"\n";
//           entireText+="______________________________________________________________________________________"+"\n";
//         }
//       }
//        stringTemp="";
//       stringTemp+=this.psiholoskaProcenas[i].misljenjePsihologa;
//       for ( j = 0; j < stringTemp.length; j++) {
//         if(stringTemp[j]=='č' ||stringTemp[j]=='ć'  ||stringTemp[j]=='đ' ){
//             entireText+="MISLJENJE PSIHOLOGA"+"\n";
//             counterHit++;
//             entireText+="Nadjen nepozeljan karakter za:"+this.psiholoskaProcenas[i].idUser +", "+j+". karakter, :"+stringTemp[j]+"\n";
//             let subStr=stringTemp.substring(j-50,j+50);
//             entireText+="tekst: "+subStr+"\n";
//             entireText+="______________________________________________________________________________________"+"\n";
//         }
//       }
//        stringTemp="";
//       stringTemp+=this.psiholoskaProcenas[i].vqHeader;
//       for ( j = 0; j < stringTemp.length; j++) {
//         if(stringTemp[j]=='č' ||stringTemp[j]=='ć'  ||stringTemp[j]=='đ' ){
//           entireText+="VQ NASLOV"+"\n";
//           counterHit++;
//           entireText+="Nadjen nepozeljan karakter za:"+this.psiholoskaProcenas[i].idUser +", "+j+". karakter, :"+stringTemp[j]+"\n";
//           let subStr=stringTemp.substring(j-50,j+50);
//           entireText+="tekst: "+subStr+"\n";
//           entireText+="______________________________________________________________________________________"+"\n";
//         }
//       }
//
//       stringTemp="";
//       stringTemp+=this.psiholoskaProcenas[i].vqBody;
//       for ( j = 0; j < stringTemp.length; j++) {
//         if(stringTemp[j]=='č' ||stringTemp[j]=='ć'  ||stringTemp[j]=='đ' ){
//             entireText+="VQ TEKST"+"\n";
//             counterHit++;
//             entireText+="Nadjen nepozeljan karakter za:"+this.psiholoskaProcenas[i].idUser +", "+j+". karakter, :"+stringTemp[j]+"\n";
//             let subStr=stringTemp.substring(j-50,j+50);
//             entireText+="tekst: "+subStr+"\n";
//             entireText+="______________________________________________________________________________________"+"\n";
//         }
//       }
//
//
//
//     }
//      //console.log(entireText);
//
//     for ( i = 0; i < entireText.length; i++) {
//       if(entireText[i]==":"&&entireText[i+1]=="6"){
//         let tempId="";
//         let x=i;
//         while(entireText[x]!=','){
//           x++;
//           tempId+=entireText[x];
//         }
//         tempId=tempId.substring(0,tempId.length-1);
//
//         for (var y = 0; y < this.users.length; y++) {
//           if(this.users[y]._id==tempId){
//
//             tempId=this.users[y].name;
//
//           }
//         }
//
//         let holder=entireText;
//         entireText=entireText.substring(0,i+1)+tempId+holder.substring(x);
//
//       }
//     }
//     console.log(entireText);
//     console.log("HITS: "+counterHit);


  },

  beforeCreate: function() {
        document.body.className = 'other';
    }
}
</script>

<style lang="css" scoped>
.headerProcena{
  text-align: center;
  border-style: solid;
  border-color: black;
  border-width: thin;


  /* width: 50%; */
  margin: 0 auto;

  background-color: white;
}
.glavni{
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
}
.page{

  border-style: solid;
  border-color: black;
  border-width: thin;

  margin-bottom: 15px;

  padding: 10px;

  /* height: 800px; */
  width:840px;

  background-image: url('~@/assets/procenaPage.png') ;
  background-size: cover;
}
.pagex{

  border-style: solid;
  border-color: black;
  border-width: thin;

  margin-bottom: 0px;

  padding: 10px;

  height: 1190px;
  width:840px;


}
.bodyProcena{
  width: 80%;
  margin: 25px auto;
  border-style: solid;
  border-color: black;
  border-width: thin;

  padding:10px;

  background-color: white;
}
.wrapper{
  margin:0 auto;
  width:840px;
}

.pdfButtons{
  background-color: #4CAF50; /* Green */
   border: none;
   color: white;
   padding: 15px 32px;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   font-size: 16px;

}

.pdfButtons1{
  background-color: #4C50AF; /* Green */
   border: none;
   color: white;
   padding: 15px 32px;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   font-size: 16px;

}
.buttonWrapper{
  margin:0 auto;
  width:840px;
  text-align: center;
}

.buttonWrapper1{


  margin:15px auto;
  text-align: center;
}
#vqTest{
  background-color: #77bbff;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  margin: 15px;
  padding: 15px;
  width: 810px;
  height: 560px;
    background: linear-gradient(110deg, #77bbff 60%, #99ddff 60%);
  margin:auto;

}
.centered{
  text-align: center;
}

.question{
  margin-top: 20px;
  border-color: black;
  border-style: solid;
  border-width: thin;

  background-color: #8CFF90;;
  padding-left: 20px;
}

.questionAlt{
  margin-top: 20px;
  border-color: black;
  border-style: solid;
  border-width: thin;

  background-color: #8C90FF;
  padding-left: 20px;
}

.wrap{
  border-color: black;
  border-style: solid;
  border-width: thin;
  padding: 15px;
}

.container {



  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". .";
  align-content: space-evenly;
}

#svaPitanja1{
  width: 810px;
  margin: 15px;
  padding: 15px;
  height: 570px;
}

#svaPitanja2{
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 15px;
  height: 570px;
}
#svaPitanja3{
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 30px;
  height: 570px;
}
#svaPitanja4{
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 30px;
  height: 570px;
}
#svaPitanja5{
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 15px;
  height: 570px;
}

#svaPitanja6{
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 30px;
  height: 570px;
}
#svaPitanja7{
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 20px;
  height: 570px;
}
.question{
  margin-top: 20px;
  border-color: black;
  border-style: solid;
  border-width: thin;

  background-color: #8CFF90;;
  padding-left: 20px;

  height: 250px;
}

.questionAlt{
  margin-top: 20px;
  border-color: black;
  border-style: solid;
  border-width: thin;

  background-color: #8C90FF;
  padding-left: 20px;

  height: 250px;
}

.question1{
  margin-top: 20px;
  border-color: black;
  border-style: solid;
  border-width: thin;

  background-color: #8CFF90;;
  padding-left: 20px;

}

.question2{
  margin-top: 20px;
  border-color: black;
  border-style: solid;
  border-width: thin;

  background-color: #8C90FF;
  padding-left: 20px;

}

#feedBackHeader{
  background-color: #ccccff;
  border-style: solid;
  border-color: black;
  border-width: medium;
  margin: 15px;
  padding: 15px;
  width: 810px;
  height: 570px;
    /* background: linear-gradient(110deg, #aaaacc 60%, #ccccff 60%); */
  text-align: center;
}

</style>
